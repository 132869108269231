// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography } from '@mui/material';
import Logo from '../../components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Quick Links',
    children: [
      { name: 'Explore', href: 'https://godwrites.com/explore/' },
      { name: 'Blog Category', href: 'https://godwrites.com/category/' },
      { name: 'Blog ', href: 'https://godwrites.com/' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      {
        name: 'Terms and Condition',
        href: 'https://godwrites.com/terms-conditions/',
      },
      { name: 'Privacy Policy', href: 'https://godwrites.com/privacy-policy/' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'About us', href: 'https://godwrites.com/about/' },
      { name: 'Contact us', href: 'https://godwrites.com/contact-us/' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
      }}
    >
      <Divider />

      <Container sx={{ pt: 8, py: 8 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={10} mb={5}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Data at Your Fingertips: Instantly access and filter your data with our intuitive
              Excel to CSV conversion and filtering features.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="body" fontWeight="bold">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      href={link.href}
                      target="_blank"
                      color="inherit"
                      variant="body2"
                      underline="none"
                      rel="noopener"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid item sx={10} mt={6} width="100%">
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              <Typography variant="body2">
                file.Godwrites.com Powered by AdsEra IT Services Pvt. Ltd.
              </Typography>
              <Typography variant="body2">Copyright © 2023 All rights reserved.</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
