// components
import { PATH_PAGE } from '../../../routes/paths';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Pdf Converter',
    icon: <Iconify icon="eva:home-fill" />,
    path: PATH_PAGE.pdfConverter,
  },
  {
    title: 'Blog',
    icon: <Iconify icon="ic:round-grain" />,
    path: 'https://godwrites.com',
  },
  {
    title: 'Explore',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: 'https://godwrites.com/explore/',
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: 'https://godwrites.com/contact-us/',
  },
];

export default navConfig;
