import { Navigate, useRoutes } from 'react-router-dom';
// auth
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
// config
//
import {
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  PdfConverterPage,
  FileImgPage,
  AboutPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'fileImg', element: <FileImgPage /> },

        { path: 'pdf-converter', element: <PdfConverterPage /> },
        // Demo Components
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: 'about-us', element: <AboutPage /> },

        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
